import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Omelet Shoppe",
  pageDescription:
    "See how a multi-location diner uses SpotOn to save time and money with cloud-based controls and reporting data.",
  title: "Data insights made over easy",
  subTitle: `How the Omelet Shoppe gets more done in less time with SpotOn.`,
  businessName: "Omelet Shoppe",
  industry: "Restaurants & hospitality, casual dining",
  location: "Dublin, Virginia; Parkersburg and Beckley, West Virginia",
  products: [spotonProducts.RESTAURANT],
  description:
    "Open since 1978, the Omelet Shoppe has 5 location of their diner-style restaurant across the Virginia area. And with SpotOn, CFO Jonathan Katsiros saves time and money with a complete restaurant POS system that can be accessed anywhere.",
  quoteData: {
    imgName: "omelet-shoppe.png",
    quote: `“Changing the menu and prices in the SpotOn back-of-house is simple. I can do it from anywhere. I've been out of town and made changes on our POS, which saves me a lot of time.”`,
    personName: "Jonathan Katsiros",
    personTitle: "CFO, Blue Ridge Best Foods Inc.",
  },
  goal: `With multiple locations, the Omelet Shop needed a cloud-based solution that allowed operators to change dishes and pricing from anywhere. Katsiros also wanted comprehensive reporting to help him optimize labor and the menu.`,
  solution: `SpotOn Restaurant and the manager app allow the Omelet Shoppe to adjust anything on the menu from any computer or phone. And with 75+ reports at his disposal, Katsiros can identify the bestsellers or pinpoint key losses.`,
  results: {
    title: `The results`,
    stats: [
      { title: `5 hrs`, description: `saved on weekly admin tasks` },
      {
        title: `$1,200`,
        description: `retained monthly revenue from voids and discounts`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Selling more crepes with smarter data",
    imageName: "crepe-corner.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-21",
    href: "/case-studies/crepe-corner",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
];
